import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Route, Navigate, useNavigate, useLocation } from 'react-router-dom';
import { generateLink } from '../../../../../shared/utils/railsRoutes';
import { ga4Event } from '../../../../../shared/utils/ga4Event';

import Address from './steps/1.Address';
import Typology from './steps/2.Typology';
import MainInfos from './steps/3.MainInfos';
import ExtraInfos from './steps/4.ExtraInfos';
import Reason from './steps/5.Reason';
import Contact from './steps/6.Contact';
import Loader from './steps/7.Loader';

import RoutesWrapper from './components/RoutesWrapper';
import Header from './components/Header';
import NavigationButtons from './components/NavigationButtons';

import { updateStep } from '../../../../reducers/_Pages/Public/estimationFunnelSlice'

const EstimationFunnel = () => {
  const { currentStep, allSteps } = useSelector((state) => state.estimationFunnel);
  const { user } = useSelector((state) => state.user);
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    ga4Event('start_estimation')
  }, [])

  useEffect(() => {
    if (!user.email) return;

    if (user.email.endsWith('@hosman.co')) {
      window.location.href = generateLink(RailsRoutes.root_path())
    }
  }, [user]);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (!currentStep) return;

    handleMatchedStep();
    handleTitleAndMetaDatas(currentStep);
    window.addEventListener('popstate', handleBackButton);
  }, [currentStep]);

  const handleMatchedStep = () => {
    const matchedStep = allSteps.find(step => step.number === currentStep.number)
    navigate(`${matchedStep.path}${location.search}`)
  }

  const handleBackButton = () => {
    if (currentStep.number === 1) {
      dispatch(updateStep({ stepNumber: 1 }))
    } else {
      dispatch(updateStep({ stepNumber: currentStep.number - 1 }))
    }
  };

  return (
    <div data-testid='EstimationFunnel' className='scrollbar-hide'>
      <Header />

      <RoutesWrapper>
        <Route index element={<Navigate to='adresse' />} />
        <Route path='/adresse' element={<Address />} />
        <Route path='/typologie' element={<Typology />} />
        <Route path='/informations' element={<MainInfos />} />
        <Route path='/specificites' element={<ExtraInfos />} />
        <Route path='/motif' element={<Reason />} />
        <Route path='/contact' element={<Contact />} />
        <Route path='/calcul-en-cours' element={<Loader />} />
      </RoutesWrapper>

      {currentStep.number != 1 &&
        <NavigationButtons classes='md:hidden sticky bottom-0 p-3 bg-white border-t border-ih-purple-30' />
      }
    </div>
  )
}

export default EstimationFunnel;

function handleTitleAndMetaDatas(currentStep) {
  document.title = `Estimation - ${currentStep.name}`;
  const metaTitleTag = document.querySelector("meta[name='title']");
  const metaDescTag = document.querySelector("meta[name='description']");

  if (metaTitleTag) {
    metaTitleTag.content = 'Estimez votre bien gratuitement en quelques clics seulement';
    metaDescTag.content = "Notre estimateur en ligne vous permet d'obtenir la fourchette de prix de votre bien en 5 minutes seulement, gratuitement et sans engagement";
  }
}
