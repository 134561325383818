import React from 'react';
import AvatarsCounter from '../../../../../../../../shared/components/AvatarsCounter';

import { ellipseFuStep1, ellipseFuStep2, ellipseFuStep3, productFuStep4 } from '../../../../../../../../shared/assets/images';

const ReinsuranceBlocks = () => {
  const similarPropertySold = () => {
    return (
      <div className='flex -space-x-4'>
        {[ellipseFuStep1, ellipseFuStep2, ellipseFuStep3].map((image, index) => {
          return <img key={index} src={image} alt='ellipse' className='w-24 h-24 rounded-full object-cover filter border border-ih-blue' />
        })}
      </div>
    )
  }

  const potentialBuyers = () => {
    return (
      <div className='flex flex-col gap-1 text-center bg-hosman-gradient-1 rounded-3xl p-5'>
        <AvatarsCounter count={1132} />
        <div className='flex flex-col gap-2'>
          <p className='h4 text-ih-indigo-100'>1132 acquéreurs potentiels</p>
          <p className='text-ih-indigo-50 text-sm'>sont intéressés par votre bien</p>
        </div>
      </div>
    )
  }

  const blocks = [
    { title: 'Une estimation précise & immédiate de votre bien', description: 'En quelques minutes seulement, recevez une première fourchette d\'estimation de votre bien.', footer: <img src={productFuStep4} alt='ellipse' className='w-44 h-32' /> },
    { title: 'Les biens vendus par Hosman, similaires au vôtre', description: 'Découvrez les derniers biens vendus autour du vôtre. Des prix exactes actualisés en temps réel.', footer: similarPropertySold() },
    { title: 'Les acquéreurs potentiels pour votre bien', description: 'Découvrez le volume d’acquéreurs potentiels ayants renseignés des critères similaires à votre bien.', footer: potentialBuyers() },
  ]

  return (
    <div className='w-screen md:w-full bg-white border-t border-ih-purple-20 py-14'>
      <div className='mx-auto px-7 sm:px-6 lg:px-8'>
        <div className='flex flex-col justify-center items-center gap-8'>
          <p className='text-ih-indigo h2'>Qu'obtiendrez-vous suite à cette estimation ?</p>

          <div className='flex flex-col md:flex-row items-stretch justify-center gap-7'>
            {blocks.map((block, index) => (
              <div key={index} className='flex-1 min-w-[250px] max-w-[350px]'>
                <div className='flex flex-col items-center gap-4 border-2 border-ih-blue-30 rounded-3xl p-5 bg-white h-full'>
                  <div className='flex justify-around mx-auto md:mx-0 items-center w-10 h-10 bg-ih-blue rounded-full text-white font-semibold'>
                    <p className='text-xl font-semibold'>0{index + 1}</p>
                  </div>

                  <p className='text-ih-indigo h3 text-center'>{block.title}</p>
                  <p className='text-ih-indigo-60 text-center'>{block.description}</p>
                  <div className='mt-auto flex justify-center'>{block.footer}</div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default ReinsuranceBlocks;
