import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button } from 'hosman-material';

import { validateStep } from '../steps/Validators';
import { updateStep, updateErrors } from '../../../../../reducers/_Pages/Public/estimationFunnelSlice';

const NavigationButtons = ({ classes }) => {
  const { currentStep, estimationData } = useSelector((state) => state.estimationFunnel);
  const { user } = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const goToNextStep = async() => {
    const contract = await validateStep({ stepNumber: currentStep.number, data: estimationData });

    if (contract.valid) {
      dispatch(updateStep({ stepNumber: currentStep.number + 1 }))
    } else {
      dispatch(updateErrors(contract.errors))

      setTimeout(() => {
        scrollToFirstError();
      }, 300);
    }
  }

  const scrollToFirstError = () => {
    const firstError = document.querySelector('[data-icon="circle-exclamation"]')
    if (!firstError) return;

    firstError.scrollIntoView({ behavior: 'smooth', block: 'center' })
  }

  const hideReturnButton = [1, 7].includes(currentStep.number)
  const hideNextButton = [7].includes(currentStep.number)

  if (hideReturnButton && hideNextButton) return null;

  const setLabel = () => {
    if (currentStep.number === 1) return "Commencer l'estimation"
    else if (currentStep.number < 5 || (currentStep.number == 5 && !user.id)) return 'Étape suivante'
    else return "Découvrir l'estimation"
  }

  return (
    <div className={classes} data-testid='NavigationButtons'>
      <div className={`flex ${hideReturnButton ? 'justify-end' : 'justify-between'}`}>
        {!hideReturnButton &&
          <div className='w-max'>
            <Button label='Retour' type='neutral' onClick={() => dispatch(updateStep({ stepNumber: currentStep.number - 1 }))} />
          </div>
        }
        {!hideNextButton &&
          <div className='w-max'>
            <Button label={setLabel()} onClick={() => goToNextStep()} />
          </div>
        }
      </div>
    </div>
  )
}

export default NavigationButtons;
