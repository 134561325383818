import { createSlice } from '@reduxjs/toolkit';
import { allSteps, matchedStep, initialPlaceData, initialMainInfos, initialExtraInfos, initialLead } from './initialData';

const initialState = () => {
  return {
    storeCreationTime: new Date(),
    allSteps: allSteps,
    currentStep: matchedStep || allSteps[0],
    estimationData: {
      placeData: initialPlaceData,
      typology: null,
      mainInfos: initialMainInfos,
      extraInfos: initialExtraInfos,
      waitingTimePeriod: null,
      nonSellingReason: null,
      lead: initialLead
    },
    errors: {},
    displayStreetNumberError: false
  }
};

export const estimationFunnelSlice = createSlice({
  name: 'estimationFunnel',
  initialState,
  reducers: {
    updateStep(state, { payload }) {
      state.errors = {}
      state.currentStep = allSteps.find(step => step.number === payload.stepNumber);
    },
    updateEstimationData(state, { payload }) {
      state.estimationData = payload;
    },
    purgeEstimationData(state) {
      const initialPayload = initialState()
      Object.assign(state, { ...initialPayload, currentStep: initialPayload.allSteps[0] });
    },
    updateFullStore(state, { payload }) {
      state.estimationData = payload;
    },
    updateErrors(state, { payload }) {
      state.errors = payload;
      if (payload.street_number) state.displayStreetNumberError = true;
    },
    updateDisplayStreetNumberError(state, { payload }) {
      state.displayStreetNumberError = payload;
    }
  },
})

export const { updateStep, updateEstimationData, purgeEstimationData, updateErrors, updateFullStore, updateDisplayStreetNumberError } = estimationFunnelSlice.actions;

export default estimationFunnelSlice.reducer;
