import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { S_Check, D_CameraRetro, D_FolderOpen, D_CalendarStar, D_House, D_FileCircleCheck, D_File, D_Loveseat, D_ScaleBalanced } from 'hosman-material';
import { crossedFingers } from '../../../../../../../../shared/assets/emojis';
import { longDateTime } from '../../../../../../../../shared/utils/formattedDates';

import Action from './components/Action';
import DownloadImagesModal from './components/DownloadImagesModal';
import { Card, Button } from 'hosman-material';

const CallToActions = ({ setPhotoMeetingModalOpen }) => {
  const { user } = useSelector((state) => state.user);
  const { sale } = useSelector((state) => state.sellerSales);
  const { photoMeetings, plannedPhotoMeeting } = useSelector((state) => state.sellerPhotoMeetings);
  const { visitsToCome } = useSelector((state) => state.sellerVisits);
  const { ongoingOffers } = useSelector((state) => state.sellerOffers);
  const classicVisitsToCome = visitsToCome.filter((visit) => visit.kind === 'classic');
  const [downloadImagesModalOpen, setDownloadImagesModalOpen] = useState(false)

  // Displayed rules
  const displayPhotoMeeting = sale.state === 'awaiting_publication' && photoMeetings.length === 0
  const displayDocuments = sale.state === 'awaiting_publication' && sale.missing_mandatory_documents
  const displayPlanning = sale.state === 'awaiting_offer' && classicVisitsToCome.length > 0
  const displayOffers = sale.state === 'awaiting_offer' && ongoingOffers.length > 0
  const displayDlPictures = sale.state === 'sold'
  const displayEnterNotary = sale.state === 'awaiting_sale_agreement' && sale.expert && !sale.seller_notary_present
  const displayPaperas = sale.state === 'awaiting_sale_agreement'
  const displayFurnitureForm = sale.state === 'awaiting_sale_agreement' && ['semi_furnished', 'furnished'].includes(sale.property.furniture)

  if ([displayPhotoMeeting, displayDocuments, displayPlanning, displayOffers, sale.availiableForVisits,
       displayDlPictures, displayEnterNotary, displayPaperas, displayFurnitureForm].filter(bool => bool === true) < 1) {
    return null;
  }

  return (
    <div className='mb-8'>
      <Card>
        <div className='flex flex-col gap-3 p-6'>
          {sale.lightshop &&
            <Action
              icon={D_CameraRetro}
              title='Réservez dès maintenant votre reportage photo'
              displayed={displayPhotoMeeting}
              important
            >
              <Button label='Prendre rendez-vous' small onClick={() => setPhotoMeetingModalOpen(true)} />
            </Action>
          }

          {plannedPhotoMeeting &&
            <Action
              icon={D_CameraRetro}
              title='Votre reportage photo a bien été confirmé'
              displayed={sale.state === 'awaiting_publication' && plannedPhotoMeeting.id !== undefined}
            >
              <div className='flex items-center gap-2'>
                <FontAwesomeIcon icon={S_Check} size='xl' className='text-ih-green' />
                <p className='text-ih-indigo semibold'>{`Rendez-vous le ${longDateTime({ date: plannedPhotoMeeting.start_date })}`}</p>
              </div>
            </Action>
          }

          <Action
            icon={D_FolderOpen}
            title='Certains documents nécessaires pour la commercialisation sont manquants'
            displayed={displayDocuments}
            important
          >
            <Link to={`/ev/ventes/${sale.id}/dossier-de-vente/documents`}>
              <Button label='Ajouter des documents' small />
            </Link>
          </Action>

          <Action
            icon={D_House}
            title={`${user.first_name} votre bien a ${classicVisitsToCome.length} visite${classicVisitsToCome.length > 1 ? 's' : ''} à venir`}
            displayed={displayPlanning}
            emoji={crossedFingers}
          >
            <Link to={`/ev/ventes/${sale.id}/planning`}>
              <Button label='Consulter le planning' small />
            </Link>
          </Action>

          <Action
            icon={D_FileCircleCheck}
            title={`Félicitations, vous avez reçu ${ongoingOffers.length} offre${ongoingOffers.length > 1 ? 's' : ''} d'achat, n'attendez-plus pour ${ongoingOffers.length > 1 ? 'les' : 'la'} visualiser !`}
            displayed={displayOffers}
          >
            <Link to={`/ev/ventes/${sale.id}/offres`}>
              <Button label={`Voir ${ongoingOffers.length > 1 ? 'les offres reçues' : "l'offre reçue"}`} small />
            </Link>
          </Action>

          <Action
            icon={D_CalendarStar}
            title='Vous souhaitez actualiser les disponibilités de votre bien pour les visites ?'
            displayed={sale.availiableForVisits}
            classes='invisible md:visible'
          >
            <Link to={`/ev/ventes/${sale.id}/planning`}>
              <Button label='Renseigner mes indisponibilités' small />
            </Link>
          </Action>

          <Action
            icon={D_ScaleBalanced}
            title='Choisissez un notaire (si besoin de conseils, contactez-moi !)'
            displayed={displayEnterNotary}
          >
            <a href={`mailto:${sale.expert?.user?.email}`}>
              <Button label="M'écrire" small />
            </a>
          </Action>

          <Action
            icon={D_File}
            title='Récupérez votre pré-état daté'
            displayed={displayPaperas}
          >
            <a href='https://www.paperas.se/preetatdate' target='_blank'>
              <Button label='Découvrir Paperas' small />
            </a>
          </Action>

          <Action
            icon={D_Loveseat}
            title='Etablissez la liste du mobilier restant dans votre bien'
            displayed={displayFurnitureForm}
          >
            <a href='https://form.jotform.com/220042192953046' target='_blank'>
              <Button label='Remplir le formulaire' small />
            </a>
          </Action>

          <Action
            icon={D_CameraRetro}
            title='Les photos de votre bien peuvent être téléchargées'
            displayed={displayDlPictures}
          >
            <Button label='Télécharger mes photos' small onClick={() => setDownloadImagesModalOpen(true)} />
          </Action>
        </div>
      </Card>

      {downloadImagesModalOpen &&
        <DownloadImagesModal property={sale.property} setDownloadImagesModalOpen={setDownloadImagesModalOpen} />
      }
    </div>
  )
}

export default CallToActions;

// PropTypes
CallToActions.propTypes = {
  setPhotoMeetingModalOpen: PropTypes.func.isRequired
};
