import React from 'react';
import { createRoot } from 'react-dom/client';
import ErrorsBoundary from './main/application/errorsBoundary';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';
import Toaster from '../_Apps/napoleon/components/Toaster';

// import rails routes and add the to window
import * as routes from '../routes.js.erb'
window.RailsRoutes = routes;

import App from './../_Apps/napoleon/containers/Apps/App'
import { setupStore } from './../_Apps/napoleon/store';

import gtmScript from '../shared/utils/gtm_script';
gtmScript()

const napoleonRoot = document.getElementById('napoleon-root');

// Set token and loggedUserId in localStorage and remove it from data-attribute
localStorage.setItem('token', napoleonRoot.dataset.token);
delete napoleonRoot.dataset.token;
localStorage.setItem('loggedUserId', napoleonRoot.dataset.loggedUserId);
delete napoleonRoot.dataset.loggedUserId;

// Set customerId in localStorage
if (napoleonRoot.dataset.customerId) {
  localStorage.setItem('customerId', napoleonRoot.dataset.customerId);
  delete napoleonRoot.dataset.customer_id;
} else {
  const url = new URL(window.location.href);
  const customerId = url.searchParams.get('customer-id');

  if (customerId) {
    localStorage.setItem('customerId', customerId);
  } else {
    localStorage.setItem('customerId', 'none');
  }
}

// Set sessionId in localStorage
localStorage.setItem('sessionId', napoleonRoot.dataset.sessionId);
delete napoleonRoot.dataset.session_id;

if (napoleonRoot) {
  const store = setupStore();
  const persistor = persistStore(store);

  const root = createRoot(napoleonRoot);
  root.render(
    <React.StrictMode>
      <ErrorsBoundary channel='NAPOLEON_MONITORING_CHANNEL'>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <BrowserRouter>
              <Toaster />
              <App />
            </BrowserRouter>
          </PersistGate>
        </Provider>
      </ErrorsBoundary>
    </React.StrictMode>
  );
}
