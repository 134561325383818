const geocoderUrl = 'https://maps.googleapis.com/maps/api/geocode/json';
const mapApiUrl = 'https://maps.googleapis.com/maps/api/js';

export async function fetchAddressData(prediction) {
  try {
    const response = await fetch(
      `${geocoderUrl}?address=${prediction}&region=fr&components=country:FR&key=${process.env.GOOGLE_API_SERVER_KEY}`
    );
    const jsonData = await response.json();
    return jsonData.results[0];
  } catch (error) {
    return null;
  }
}

export function extractAddress(name, place) {
  if (!place?.address_components) return null;

  const addressComponents = place.address_components;
  return {
    [name]: place.formatted_address,
    street_number: addressComponents.find(t => t.types[0].includes('street_number'))?.long_name,
    street_name: addressComponents.find(t => t.types[0].includes('route'))?.long_name,
    city: addressComponents.find(t => t.types[0].includes('locality'))?.long_name,
    zip_code: addressComponents.find(t => t.types[0].includes('postal_code'))?.long_name,
    country_code: addressComponents.find(t => t.types[0].includes('country'))?.short_name,
    lat: place.geometry?.location?.lat,
    lng: place.geometry?.location?.lng
  };
}

export function initMapScript() {
  if (window.google) {
    return Promise.resolve();
  }

  window.initMap = function() {};
  const src = `${mapApiUrl}?key=${process.env.GOOGLE_API_BROWSER_KEY}&libraries=places&language=fr&callback=initMap`;
  return loadAsyncScript(src);
}

function loadAsyncScript(src) {
  return new Promise(resolve => {
    const script = document.createElement('script');
    Object.assign(script, {
      type: 'text/javascript',
      async: true,
      src
    })
    script.addEventListener('load', () => resolve(script));
    document.head.appendChild(script);
  })
}
